

.scrollerComponent {
  &:not(.first-component) {
    position: relative;
    z-index: 2;
    background-color: $white;
  }

  &.first-component {
    // background-color: gray;
    // position: relative;
    padding-top: 0;
    .contentWrapper {
      position: absolute;
      overflow: visible;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      align-content: center;
    }

    #canvasWrapper {
      position: relative;
      width: 100vw;
      height: 100vh;
      // filter: grayscale(0.75);
      opacity: 0.4;
    }

    &::after {
      display: block;
      @media (max-width: $lg-breakpoint) {
        display: none;
      }
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 10vh;
      min-height: 50px;
      max-height: 100px;
      background: -moz-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 65%,
        $light-gray 100%
      );
      background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 65%,
        $light-gray 100%
      );
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 65%,
        $light-gray 100%
      );
      z-index: 2;
    }
  }
}

.scrollerComponent,
.pageComponent {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;

  .narrowContent {
    max-width: 900px;
    padding-left: $normalPadding;
    padding-right: $normalPadding;
    margin: auto;
  }
  &.projects-wrap {
    background-color: $light-gray;
  }

  &.testimonials-wrap {
    background-color: $darker-bg;
  }
}


.testimonials-wrap{
  align-items: flex-start;
  .containerWrap{
    min-height: 65vh;
  }
  .tabsWrap{
    position: relative;
    z-index: 1;
    border-bottom: 1px solid rgba($white,0.2);
    button{
      font-family: "Inter";
      text-transform: none;
      font-weight: 700;
      &[aria-selected="false"]{
        color: $white;
        
      }
      &.Mui-selected{
        color: $white;
      }
    }

  }
  .MuiTabs-scrollButtons{
    width: 25px;
    svg{
      path{
        fill: $white;
      }
    }
    &.Mui-disabled {
      visibility: hidden;
    }
  }
  .tabContent{
    color: $white;
    font-family: "Nunito";
    text-align: left;
    h6{
      max-width: 600px;
      
      font-size: 1.25rem;
        line-height: 1.4;
      @media (max-width: $sm-breakpoint) {
        font-size: 1.05rem;
        line-height: 1.25;  
      }
    }
  }
  .narrowContent{
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-top: 0;
    min-height: 100vh;

    @media (min-width: $lg-breakpoint) {
      align-items: center;
      display: flex;
    }
    

  }
  .tabContenstWrap{
    position: relative;
    padding-top: $normalPadding*2;
    .author{
      text-align: right;
      display: inline-block;
      border-top: 1px solid rgba(255, 255, 255, 0.15);
      padding-left: $normalPadding*2;
      padding-top: $normalPadding;
      margin-top: $normalPadding*2;
    }
    .authorWrap{
      text-align: right;
    }
    .quote{
      position: absolute;
      top: 0;
      left: 0;
      color: $white;
      font-family: "Inter";
      font-size: 8rem;
      line-height: 8rem;
      @media (max-width: $md-breakpoint) {
        top: $normalPadding;
        font-size: 5rem;
        line-height: 5rem;
      }
      z-index: 0;
    }
  }
}