$logoSize: 10px;

.logo,.static-logo {
  text-align: center;
  width: $logoSize * 4;
  height: $logoSize * 4;
  position: relative;
  -webkit-filter: url(#ball);
  filter: url(#ball);
  margin-right: $logoSize;
  .ball {
    display: block;
    width: $logoSize;
    height: $logoSize;
    border-radius: 50%;
    background-color: $brand-primary;
    position: absolute;
    top: $logoSize + ($logoSize/2);
    left: $logoSize + ($logoSize/2);
  }
}

.logo{
  .ball {
    &:nth-child(1) {
      animation: ball1 2s infinite;
    }
    &:nth-child(2) {
      animation: ball2 1.5s infinite;
    }
    &:nth-child(3) {
      animation: ball3 2.5s infinite;
    }
    &:nth-child(4) {
      animation: ball4 1s infinite;
    }
  }
}

.static-logo{
  .ball {
    &:nth-child(1) {
      transform: translateX(-$logoSize);
    }
    &:nth-child(2) {
      transform: translateX($logoSize);
    }
    &:nth-child(3) {
      transform: translateY($logoSize);
    }
    &:nth-child(4) {
      transform: translateY(-$logoSize);
    }
  }
}

.logoTextWrap{
  .logoText {
    display: block;
    font-family: "Montserrat";
    font-weight: 700;
    letter-spacing: 0;
    color: white;
  }
  a{
    text-decoration: none !important;
  }
}

.logoWrapper {
  position: relative;
  display: flex;
  align-items: center;
  svg{
    position: absolute;
    max-width: 150px;
    max-height: 85px;
    @media (max-width: $sm-breakpoint) {
      max-height: 45px;
    }
  }
  a{
    text-decoration: none !important;
  }
  
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.75;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes ball1 {
  0% {
    transform: translateX(-$logoSize);
  }
  40% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-$logoSize);
  }
}
@keyframes ball2 {
  0% {
    transform: translateX($logoSize);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX($logoSize);
  }
}
@keyframes ball3 {
  0% {
    transform: translateY(-$logoSize);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-$logoSize);
  }
}
@keyframes ball4 {
  0% {
    transform: translateY($logoSize);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY($logoSize);
  }
}

/* .drop-shadow {
  width: 70px;
  height: 15px;
  background-color: rgba(44, 62, 80, 0.5);
  margin: 10px auto;
  border-radius: 50%;
  filter: blur(7px) contrast(30);
  animation: shadow 2s infinite;
} */

@keyframes shadow {
  0% {
    transform: scaleY(1.2) scaleX(1.2);
  }
  30% {
    transform: scaleY(1.1) scaleX(1.1);
  }
  50% {
    transform: scaleY(1) scaleX(1);
  }
  30% {
    transform: scaleY(1.1) scaleX(1.1);
  }
  100% {
    transform: scaleY(1.2) scaleX(1.2);
  }
}
