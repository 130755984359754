.drawerBackground {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
}

.drawerBtn {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: fixed;
  top: 24px;
  right: 28px;
  z-index: 9999;
  svg {
    position: relative;
    top: 2px;
  }
  &:not(.open) {
    mix-blend-mode: difference;
  }
  &.open {
    svg path{
      stroke: white !important;
    }
  }
}

  

.drawerNav {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  &.closed {
    ul,.langs {
      display: none !important;
    }
    z-index: 4;
    width: 0;
  }
  &.closing{
   .menuBtnLink,.langs{
    visibility: hidden;
   }
  }
  width: 100vw;

  ul,
  li {
    margin: 0;
    padding: 0;
  }

  & ul {
    padding: 25px;
    position: absolute;
    top: 50px;
    & > li {
      width: 100%;
      display: block;
    }
  }

  .langs{
    
    padding: $normalPadding $normalPadding+5;
    position: absolute;
    bottom: 75px;
    right: 0;
    display: flex;
    display: none;
    align-items: flex-end;
    button{
      text-transform: lowercase;
      color: $white;
      &.current{
        font-weight: 900 !important;
      }
    }
  }

  

  $iconSize: 10px;

  

  .menuBtnLink {
    background-color: transparent !important;
    color: $white;
    list-style: none;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    position: relative;
    .icon-placeholder {
      width: $iconSize;
      height: $iconSize;
      border-radius: 50%;
      flex: $iconSize 0;
      margin-right: $iconSize;
      background-color: $white;
      transition: all 0.25s ease-in-out;
      transform: scale(0.5);
      margin-top: -1px;
    }

  }

  li.current .menuBtnLink,
  li .menuBtnLink:hover{
    .icon-placeholder {
      transform: scale(1.5);
      background-color: $brand-primary;
    }
  }
}
