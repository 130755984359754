body{
    &:not([data-current-page-key="0"]){
        header,.minimal-navigation-logo{
            .logo{
                display: block;
            }
            .static-logo{
                display: none !important;
            }
        }
    }
    &[data-current-page-key="0"]{
        header,.minimal-navigation-logo{
            .static-logo{
                display: block;
            }
            .logo{
                display: none !important;
            }
        }
    }
    
}

header{
    background-color: $white;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0px 2px 4px -1px rgba(255,255,255,0.2), 0px 4px 5px 0px rgba(255,255,255,0.14), 0px 1px 10px 0px rgba(255,255,255,0.12) !important;
}

.minimal-navigation-logo{
    position: fixed;
    top: $normalPadding;
    left: $normalPadding;
    z-index: 9999;
}

.logoTextWrap{
    position: fixed;
    top: 19px;
    left: 65px;
    z-index: 9999;
}