.projects-wrap{
    .narrowContent{
        width: 100%;
        max-width: none;
    }
    .gridContainer{
        align-items: stretch;
    }
    .gridItem{
        flex-grow: 1;
    }
    .card{
        filter: grayscale(1);
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        margin: $normalPadding*1.5;
        .clientName{
            display: none;
        }
    }
    .secondary{
        .card{
            margin: $normalPadding*2;
            opacity: 0.6;
            @media (max-width: $sm-breakpoint) {
                margin: $normalPadding/1.25;
            }
        }
        .gridItem{
            max-width: 150px;
        }
        padding-bottom: 25vh;
    }
    .primary{
        .card{
            position: relative;
            transition: all 0.5s;
        }
        .previewImg{
            transition: all 0.75s;
        }
        .logoImg{
            transition: all 0.25s;
        }
        .logoImg, .previewImg{
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            opacity: 1;
            transform: translate3d(0, 0, 0);
            -webkit-transform: translate3d(0, 0, 0);
            -webkit-filter: blur(0px); 
        }
        .previewImg{
            background-position: top center;
            background-size: cover;
            opacity: 0;
            transform: translate3d(0, 0, 0) scale(1);
        }
        .card:hover{
            filter: grayscale(0);
            background-color: $light-gray;
            .previewImg{
                opacity: 1;
                transform: translate3d(0, 0, 0) scale(1);
                -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.4);
                -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.4);
                box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.4);
                filter: none; 
                -webkit-filter: blur(0px); 
                -moz-filter: blur(0px); 
                -ms-filter: blur(0px);
                 filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='0');
                 image-rendering: optimizeQuality;
            }
            .logoImg{
                opacity: 0;
            }
            transition-duration: 0.25s;
            transition-timing-function: ease-in;
            transition-property: transform;
            // animation-iteration-count: 1;
            // transform: scale(1);
            -webkit-filter: blur(0px); 
            transform: translate3d(0, 0, 0) scale(1);
            -webkit-transform: translate3d(0, 0, 0) scale(1);
            position: relative;
            backface-visibility: hidden;
            cursor: pointer;
            &:hover {
                -webkit-filter: blur(0px); 
                transform: translate3d(0, 0, 0) scale(2.5);
                z-index: 2;
                // transform: scale(2);
              }
            
        }
        .cardWrap{
            max-width: 250px;
            margin: auto;
            padding: $normalPadding;
            
        }
    }
    .sectionHeading{
        min-height: 40vh;
    }
}


.sectionHeading{
    h2{
        font-size: 6rem;
        @media (max-width: $sm-breakpoint) {
            font-size: 3.5rem;
        }
    }
}

.MuiTypography-h1{
    @media (max-width: $sm-breakpoint) {
        font-size: 4rem !important;
    }
}

.services-wrap{
    .servicesHeadline{
        @media (max-width: $lg-breakpoint) {
            font-size: 5rem !important;
        }
        @media (max-width: $md-breakpoint) {
            font-size: 4rem !important;
        }
        @media (max-width: $sm-breakpoint) {
            font-size: 3.5rem !important;
        }
    }
}

.welcomeScreen{
    .narrowContent{
        position: relative;
        .motionWrap{
            position: relative;
            z-index: 2;
            transform: translateY(0px) translateZ(0px);
        }
    }
    .fakePlaceholder{
        padding-left: $normalPadding;
        padding-right: $normalPadding;
        visibility: hidden;
    }
    .windUpWrap{
        position: absolute;
        top: 0;
        left: $normalPadding;
        right: $normalPadding;
    }

    &.textWritten.welcomeCardsShown{
        .fakePlaceholder{
            visibility: visible;
        }
        .windUpWrap{
            display: none;
        }
    }
}


.hpCardButton{
    color: rgba(0, 0, 0, 0.87) !important;
    text-transform: lowercase !important;
    z-index: 2;
    position: relative;
    &,&:hover{
        background: transparent !important;
        
    }
    svg path {
        transition-property: transform, opacity;
        transition-duration: 0.4s;
        transition-delay: 0.2s;
        transform-origin: center;
      }
    .iconWrap{
        display: inline-flex;
        margin-top: -1px;
        svg path {
            transform: scale(0.4);
            opacity: 0.4;
          }
    }
   
    &:hover{
        svg path {
            
            fill: $brand-primary;
            transform: scale(1);
            opacity: 1;
          }
    }

    
    @media (max-width: $sm-breakpoint) {
        justify-content: flex-start !important;
    }
}

html{
    scroll-behavior: smooth;
//     scroll-snap-type: y mandatory;
//     scroll-behavior: smooth;
}

// .welcomePageWrap{
//     .scrollerComponent{
//         scroll-margin: 10px;
//         scroll-snap-align: start;
//         scroll-snap-stop: normal;
//     }
// }