.blend{
    mix-blend-mode: difference;
    background-color: #000;
}

body {
    background-color: $white;
    // background-image: radial-gradient(circle, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

.text-primary{
    color: $brand-primary;
}

.introWrap{
    
    //absolute inset-0 flex items-center justify-center
    .introAnimWrap{
        // relative z-50 w-full bg-black
        position: fixed;
        // position: relative;
        opacity: 0.7;
        height: 100vh;
        width: 100vw;
        right: 0;
        top: 0;
        z-index: 5;
        background: white;
        filter: blur(8px);
        -webkit-filter: blur(8px);
    }
}

.clickable{
    cursor: pointer;
}

.animateTextColor{
    transition: color;
    transition-duration: 1s;
    transition-delay: 0.25;
}

.services-wrap{
    .text-written{
        .animateTextColor{
            color: $brand-primary;
        }
    }
    .minScreenDimensions{
        align-items: center;
        display: flex;
    }

    .services-points{
        .narrowContent {
            max-width: 1000px;
          }
        background-color: $brand-black;
        color: $light-gray;
        h3{
            color: $dark-gray;
            transition: color 1s;
        }
        .textWrap{   
            color: rgba($light-gray,0.65);    
            strong{
                transition: color 1s;
            }
        }
        .completedPoint{
            h3{

                color: $white;
            }
            .textWrap{
                
                strong{

                    color: $white;
                }
            }
        }
    }
}

.services-wrap.pageComponent{
    .minScreenDimensions{
        min-height: 100vh;
    }
}

.windUpWrap{
    position: relative;
    .windUpText{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .windUpInvisible{
        visibility: hidden;
    }
    &.text-written{
        .windUpText{
            display: none;
        }
        .windUpInvisible{
            visibility: visible;
        }
    }
    
}


.minScreenDimensions{
    min-width: 100%;
    min-height: 100vh;
}

.page404{
    background-color: #000;
    background-image: url("~/public/images/404-bg.jpg");
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: cover;
    filter: grayscale(0.75);
    .sectionHeading{
        color: $brand-black;
        mix-blend-mode: overlay;
        svg path{
            mix-blend-mode: difference;
            color: $white;
        }
    }
}

