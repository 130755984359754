body {
  --black: #000000;
  --ash-black: #222;
  --white: #fafafa;
  --sky: #00ccff;
  --green: #22dddd;
  --blue: #1300ff;
  --dusk: #6600ff;
  --purple: #9900ff;
  --pink: #ff0066;
  --red: #fe0222;
  --orange: #fd7702;
  --yellow: #ffbb00;
  --brand: #255e8d;

  --background: var(--white);
  --accent: var(--brand);

  margin: 0;
  padding: 0;
  background-color: var(--background);
  color: var(--accent);
  overflow-x: hidden;
}

a {
  color: var(--accent);
}

ul,
li {
  padding: 0;
  margin: 0;
}

ul {
  width: 100%;
  padding-top: 25vw;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  list-style: none;
}

li {
  font-size: 2rem;
  margin-bottom: 1.6rem;
}

* {
  -webkit-font-smoothing: antialiased;
}

h1 {
  --base-width: 8.2vw;
  font-size: clamp(20px, var(--base-width), 700px);
  font-weight: 700;
  letter-spacing: -0.15rem;
  line-height: 1.2;
  text-align: center;

  // white-space: nowrap;
}

h2 {
  font-size: clamp(20px, 6vw, 700px);
  font-weight: 400;
  margin: 50px 0 10px;
}

p {
  margin: 0 0 30px 0;
  font-size: 18px;
}

#sandbox-title {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  border-top: 1px dotted var(--accent);
  background: rgba(255, 255, 255, 0.7);
  background-size: 4px 4px;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  -moz-backdrop-filter: blur(4px);
  font-size: 14px;
  line-height: 14px;
}

#sandbox-title svg {
  margin-right: 20px;
}

#sandbox-title a {
  text-decoration: none;
  color: var(--accent);
}

code {
  font-family: input-mono, monospace;
  font-weight: 400;
  font-style: normal;
}

#progress {
  position: fixed;
  top: 20px;
  left: 20px;
  transform: rotate(-90deg);
}

circle {
  stroke-dashoffset: 0;
  stroke-width: 15%;
  fill: none;
}

.bg {
  stroke: var(--accent);
  opacity: 0.3;
}

#progress .indicator {
  stroke: var(--accent);
}

// ::-webkit-scrollbar {
//   height: 5px;
//   width: 5px;
//   background: var(--background);
//   -webkit-border-radius: 1ex;
// }

// ::-webkit-scrollbar-thumb {
//   background: var(--accent);
//   -webkit-border-radius: 1ex;
// }

// ::-webkit-scrollbar-corner {
//   background: #fff3;
// }

.projectWrap {
  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  .projectLogo{
    position: relative;
    width: auto;
    height: auto;
    max-width: 80%;
  }

  .progress {
    position: fixed;
    left: 0;
    right: 0;
    height: 4px;
    background: var(--accent);
    bottom: 100px;
  }
  .progressBg {
    display: block;
    height: 4px;
    background-color: rgba(255, 255, 255, 0.4);
    position: fixed;
    left: 0;
    bottom: 100px;
    right: 0;
  }

  .back {
    padding: 100px;
    display: flex;
    justify-content: center;
    align-self: center;
  }

  .privacy-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $custom-gray;
    z-index: 5;
  }

  section {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    scroll-snap-align: center;
    perspective: 500px;
    .textPart {
      position: absolute;
      left: calc(75vw);
      background-color: transparent;
      mix-blend-mode: multiply;
      h2 {
        margin: 0;
        color: var(--accent);

        font-weight: 700;
        letter-spacing: -3px;
        line-height: 1.2;
      }
      p {
        mix-blend-mode: normal;
        background: $white;
        padding: $normalPadding/2 $normalPadding;
      }
    }
  }

  section > div.imageWrap {
    aspect-ratio: 4/3;
    height: 80vh;
    position: relative;
    max-height: 90vh;
    margin: 20px;
    background: var(--white);
    overflow: hidden;
    background: var(--ash-black);
  }

  section > div.videoDisplayWrap {
    aspect-ratio: 1.8/1;
    height: 80vh;
    max-height: 90vh;

    position: relative;
    margin: 20px;
    overflow: hidden;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    video {
      position: absolute;
      top: 45px;
      left: 0px;
      right: 0px;
      width: 100%;
    }
    &::before {
      display: block;
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      // https://browserframe.com/
      background: url("~/public/images/frame_safari_dark.png") top center
        no-repeat;
      background-size: contain;
    }
  }
}

// .scroll--disabled{
//   overflow: hidden !important;
// }
