$white: #ffffff;
$custom-gray: #F5F5F5;
$light-gray: #F5F5F5;
$dark-gray: #B8B8B8;
$drawer-bg: #1F1F1F;
$brand-primary: #255E8D;
$darker-bg: #141414;
$brand-black: rgba(22, 22, 22, 0.9);
$border-black: rgba(44, 44, 44, 0.4);
$headerHeight: 64px;
$normalPadding: 15px;



@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&family=Nunito:wght@300;700&family=Montserrat:wght@800&display=swap&subset=latin-ext');

$font-family-sans-serif: 'Nunito', Helvetica, sans-serif !important;


// xs: 0,
// sm: 600,
// md: 900,
// lg: 1200,
// xl: 1536,
$sm-breakpoint: 600px;
$md-breakpoint: 900px;
$lg-breakpoint: 1200px;
$xl-breakpoint: 1536px;